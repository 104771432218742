<template>
    <div>
        <title-bar v-if="form" :title="form.type.name"/>

        <b-card>
            <table
                v-if="params"
                class="table b-table table-striped table-bordered form-table"
            >
                <tbody>
                <tr>
                    <td>
                        <strong>Submitted by:</strong>
                    </td>
                    <td>
                        <strong>{{
                                form.employee.givenName + " " + form.employee.familyName
                            }}</strong>
                    </td>
                </tr>
                <tr v-for="(item, index) in params" :key="index" :class="item.className">
                    <th v-if="item.key === 'Tool'" colspan="2">
                        {{ item.value }}
                    </th>
                    <th v-if="item.key === 'Tail number'">
                        {{ item.key }}
                    </th>
                    <th v-if="item.key === 'Tail number'">
                        {{ item.value }}
                    </th>
                    <td v-if="item.key != 'Tool' && item.key != 'Tail number'">{{ item.key }}</td>
                    <td v-if="item.key != 'Tool' && item.key != 'Tail number'">{{ item.value }}</td>
                </tr>
                </tbody>
            </table>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";

export default {
    components: {
        TitleBar,
    },
    data() {
        return {
            form: null,
            params: null,
        };
    },
    created() {
        const id = this.$route.params.id;
        this.$Forms.getResource({id}).then((response) => {
            this.form = response.data;
            const params = new URLSearchParams(this.form.data);
            this.params = [];
            const yesAnswers = [this.$t('forms.acDelayed'), this.$t('forms.communicationMcc'), this.$t('forms.wetEngine'), this.$t('forms.woOpen')]
            const noAnswers = [this.$t('forms.stocksUpdated'), this.$t('forms.toolingChecked'), this.$t('forms.toolboxChecked'), this.$t('forms.toolingServicable'), this.$t('forms.closeWo'), this.$t('forms.openingAc'), this.$t('forms.handlingWizz'), this.$t('forms.n2Quantity'), this.$t('forms.companyCarOk'), this.$t('forms.officeStock')]

            for (const param of params) {
                let className = ""
                if (param[1] !== "") {
                    if ((yesAnswers.includes(param[0]) && param[1] === "Yes") || (noAnswers.includes(param[0]) && param[1] === "No")) {
                        className = "danger"
                    }
                    this.params.push({
                        key: param[0],
                        value: param[1],
                        className: className
                    });
                }
            }
        });
    },
};
</script>

<style>
</style>
